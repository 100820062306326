import { defineAsyncComponent } from 'vue';

const DefaultLayout = defineAsyncComponent(
  () => import('@/components/layouts/default/DefaultLayout.vue')
);

const EmptyLayout = defineAsyncComponent(
  () => import('@/components/layouts/EmptyLayout.vue')
);

const SidebarLayout = defineAsyncComponent(
  () => import('@/components/layouts/sidebar/SidebarLayout.vue')
);

export { DefaultLayout, EmptyLayout, SidebarLayout };

import { useUserStore } from '@/store';

export function hasPermissionGuard(permission: string) {
  return (to, from, next) => {
    const userStore = useUserStore();

    if (userStore.hasPermission(permission)) {
      return next();
    }

    next('/403');
  };
}

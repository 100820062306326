export class Glossary implements TranslationViewGlossaryResponse {
  readonly glossary: GlossaryInterface;
  readonly glossary_term: GlossaryTerm;
  readonly term_translated?: GlossaryTerm;

  static createEmpty(): Glossary {
    return new this({
      glossary: {} as GlossaryInterface,
      glossary_term: {} as GlossaryTerm,
      term_translated: undefined,
    });
  }

  constructor(data: TranslationViewGlossaryResponse) {
    this.glossary = data.glossary;
    this.glossary_term = data.glossary_term;
    this.term_translated = data.term_translated;
  }
}

import { useTaskStore } from '@/modules/articles/store';
import Sortable from 'sortablejs';
import { v4 } from 'uuid';
import { computed, Ref, ref, watch } from 'vue';
import { TaskContentService } from '@/services';
import { useDebounce } from '@/composables/useDebounce';

const uuid = ref<string>(v4());

export function useTaskSegmentSortable(item: Ref<HTMLElement>) {
  const taskStore = useTaskStore();
  const sortable = ref<Sortable>();
  const debounce = useDebounce();

  watch(item, resetSortable, { immediate: true });

  function resetSortable(): void {
    destroySortable();
    initSortable();
  }

  function destroySortable(): void {
    if (sortable.value === undefined) {
      return;
    }
    sortable.value.destroy();
    sortable.value = undefined;
  }

  function initSortable(): void {
    if (!item.value || !taskStore.task.isEditable) {
      return;
    }

    // https://jsfiddle.net/2soj9gkL/17/
    sortable.value = new Sortable(item.value, {
      handle: '.drag-handle',
      group: 'shared',
      swap: true,
      animation: 250,
      onEnd: ({ from, to }: { from: HTMLElement; to: HTMLElement }) => {
        const source = getSegmentId(from);
        const destination = getSegmentId(to);
        taskStore.task.preventSave();
        taskStore.task.preventModify();

        debounce.debounce(async () => {
          await TaskContentService.swapTaskSegmentText(source, destination);
          taskStore.task.allowSave();
          taskStore.task.allowModify();
        });
      },
    });
  }

  function getSegmentId(element: HTMLElement): number {
    return Number(element.id.split('-')[1]);
  }

  return computed(() => uuid.value);
}

export default {
  shortkeys: {
    key: 'shortkeys',
    type: 'json',
    value: [
      {
        name: 'global',
        value: [
          {
            name: 'switchMode',
            value: 'f2',
            display: 'F2',
          },
          {
            name: 'showModalShortkeyHelper',
            value: 'f1',
            display: 'F1',
          },
        ],
      },
      {
        name: 'glossaryAdministration',
        value: [
          {
            name: 'openAddGlossaryTermModal',
            value: 'ctrl+m',
            display: 'ctrl+m',
          },
          {
            name: 'openAddGlossaryTermModalWithSelectedText',
            value: 'ctrl+shift+m',
            display: 'ctrl+shift+m',
          },
        ],
      },
      {
        name: 'tasks',
        value: [
          {
            name: 'previous',
            value: 'ctrl+alt+up',
            display: 'ctrl+alt+ArrowUp',
          },
          {
            name: 'next',
            value: 'ctrl+alt+down',
            display: 'ctrl+alt+ArrowDown',
          },
          {
            name: 'openCombineMode',
            value: 'ctrl+b',
            display: 'ctrl+b',
          },
          {
            name: 'splitSegment',
            value: 'ctrl+l',
            display: 'ctrl+l',
          },
          {
            name: 'openAddGlossaryTermModal',
            value: 'ctrl+g',
            display: 'ctrl+g',
          },
          {
            name: 'openAddGlossaryTermModalWithSelectedText',
            value: 'ctrl+shift+g',
            display: 'ctrl+shift+g',
          },
          {
            name: 'toggleRightSidebar',
            value: 'alt+p',
            display: 'alt+p',
          },
          {
            name: 'finish',
            value: 'alt+s',
            display: 'alt+s',
          },
          {
            name: 'undo',
            value: 'ctrl+z',
            display: 'ctrl+z',
          },
          {
            name: 'redo',
            value: 'ctrl+shift+z',
            display: 'ctrl+shift+z',
          },
        ],
      },
    ],
  },
  macShortkeys: {
    key: 'macShortkeys',
    type: 'json',
    value: [
      {
        name: 'global',
        value: [
          {
            name: 'switchMode',
            value: 'f2',
            display: 'F2',
          },
          {
            name: 'showModalShortkeyHelper',
            value: 'f1',
            display: 'F1',
          },
        ],
      },
      {
        name: 'glossaryAdministration',
        value: [
          {
            name: 'openAddGlossaryTermModal',
            value: 'meta+m',
            display: 'meta+m',
          },
          {
            name: 'openAddGlossaryTermModalWithSelectedText',
            value: 'meta+shift+m',
            display: 'meta+shift+m',
          },
        ],
      },
      {
        name: 'tasks',
        value: [
          {
            name: 'previous',
            value: 'ctrl+meta+up',
            display: 'ctrl+meta+ArrowUp',
          },
          {
            name: 'next',
            value: 'ctrl+meta+down',
            display: 'ctrl+meta+ArrowDown',
          },
          {
            name: 'openCombineMode',
            value: 'meta+b',
            display: 'meta+b',
          },
          {
            name: 'splitSegment',
            value: 'meta+l',
            display: 'meta+l',
          },
          {
            name: 'openAddGlossaryTermModal',
            value: 'meta+g',
            display: 'meta+g',
          },
          {
            name: 'openAddGlossaryTermModalWithSelectedText',
            value: 'meta+shift+g',
            display: 'meta+shift+g',
          },
          {
            name: 'toggleRightSidebar',
            value: 'meta+p',
            display: 'meta+p',
          },
          {
            name: 'finish',
            value: 'meta+s',
            display: 'meta+s',
          },
          {
            name: 'undo',
            value: 'meta+z',
            display: 'meta+z',
          },
          {
            name: 'redo',
            value: 'meta+shift+z',
            display: 'meta+shift+z',
          },
        ],
      },
    ],
  },
};

export class FormDataParser {
  /**
   * @param {object} object
   * @param {FormData} formData
   * @returns {FormData}
   */
  static parse(object: object, formData: FormData = undefined): FormData {
    const form = formData || new FormData();

    FormDataParser.parseObject(form, '', object);

    return form;
  }

  /**
   * @param {FormData} formData
   * @param {string} key
   * @param {unknown[]} value
   * @private
   */
  private static parseArray(
    formData: FormData,
    key: string,
    value: unknown[]
  ): void {
    value.forEach((data, idx) => {
      const formDataKey = `${key}[${idx}]`;

      FormDataParser.processParse(
        formData,
        formDataKey,
        data as unknown[] | object
      );
    });
  }

  /**
   * @param {FormData} formData
   * @param {string} key
   * @param {object} object
   * @private
   */
  private static parseObject(
    formData: FormData,
    key: string,
    object: object
  ): void {
    Object.entries(object).forEach(([objectKey, value]) => {
      const formDataKey = key ? `${key}[${objectKey}]` : `${objectKey}`;

      FormDataParser.processParse(formData, formDataKey, value);
    });
  }

  /**
   * @param {FormData} formData
   * @param {string} key
   * @param {object | unknown[]} value
   * @private
   */
  private static processParse(
    formData: FormData,
    key: string,
    value: object | unknown[]
  ): void {
    if (Array.isArray(value)) {
      FormDataParser.parseArray(formData, key, value);
      return;
    }

    if (!value) {
      return;
    }

    if (FormDataParser.isObject(value)) {
      FormDataParser.parseObject(formData, key, value);
      return;
    }

    FormDataParser.append(formData, key, value);
  }

  /**
   * @param value
   * @returns {boolean}
   * @private
   */
  private static isObject(value: unknown): boolean {
    return typeof value === 'object' && !(value instanceof File);
  }

  /**
   * @param {FormData} formData
   * @param {string} key
   * @param value
   * @private
   */
  private static append(formData: FormData, key: string, value: unknown): void {
    if (typeof value === 'function') {
      throw `Functions ${key} should get resolved before attached to form data`;
    }

    let formDatableValue = value;

    if (typeof value === 'boolean') {
      formDatableValue = value ? '1' : '0';
    }

    if (!value) {
      return;
    }

    formData.append(key, formDatableValue as string | Blob);
  }
}

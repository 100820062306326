import {
  inject,
  InjectionKey,
  provide,
  readonly,
  ref,
  DeepReadonly,
} from 'vue';
import { NotificationType } from '@/components/core/BaseNotification';

export interface NotificationInterface {
  id?: number;
  title: string;
  message: string;
  type?: NotificationType;
  rounded?: boolean;
  time?: number;
  closed?: boolean;
  hidden?: boolean;
  read?: boolean;
}

interface Context {
  open: (notification: NotificationInterface) => void;
  close: (notificationId: Pick<NotificationInterface, 'id'>) => void;
  hide: (notificationId: Pick<NotificationInterface, 'id'>) => void;
  remove: (notificationId: Pick<NotificationInterface, 'id'>) => void;
  markAsRead: (
    notificationId: Pick<NotificationInterface, 'id'> | null
  ) => void;
  all: DeepReadonly<typeof notifications>;
  unreadCount: typeof unreadCount;
}

const maxActive = 3;
const notifications = ref<NotificationInterface[]>([]);
const unreadCount = ref<number>(0);

// https://logaretm.com/blog/type-safe-provide-inject/
export const NOTIFICATION_CONTEXT: InjectionKey<Context> =
  Symbol('notification');

function getNotificationIndexById(
  notification: Pick<NotificationInterface, 'id'>
): number {
  return notifications.value.findIndex(
    (notificationItem) => notificationItem.id === notification.id
  );
}

export function useNotificationProvider(): void {
  /**
   * open a new notification
   * @param {NotificationInterface} notification
   */
  function open(notification: NotificationInterface): void {
    notification.id = Math.floor(Math.random() * 99999) + 1;
    notification.closed = false;
    notification.read = false;
    notifications.value.unshift(notification);
    unreadCount.value++;

    if (notifications.value.length <= maxActive) {
      return;
    }

    notifications.value.forEach((notification, index) => {
      if (index <= maxActive - 1) {
        return;
      }

      notification.closed = true;
    });
  }

  /**
   * close notification by index
   * @param notification
   */
  function close(notification: Pick<NotificationInterface, 'id'>): void {
    const notificationIndex = getNotificationIndexById(notification);

    if (notificationIndex === -1) {
      return;
    }

    const updatedNotification = {
      ...notifications.value[notificationIndex],
      closed: true,
    };

    notifications.value.splice(notificationIndex, 1, updatedNotification);
  }

  /**
   * hide notification by index
   * @param notification
   */
  function hide(notification: Pick<NotificationInterface, 'id'>): void {
    const notificationIndex = getNotificationIndexById(notification);

    if (notificationIndex === -1) {
      return;
    }

    const updatedNotification = {
      ...notifications.value[notificationIndex],
      hidden: true,
    };

    unreadCount.value -= 1;
    notifications.value.splice(notificationIndex, 1, updatedNotification);
  }

  /**
   * removes a notification completely from notification list
   * @param notification
   */
  function remove(notification: Pick<NotificationInterface, 'id'>): void {
    const notificationIndex = getNotificationIndexById(notification);
    if (notificationIndex === -1) {
      return;
    }

    notifications.value.splice(notificationIndex, 1);
  }

  /**
   * mark notification as read
   */
  function markAsRead(
    notification: Pick<NotificationInterface, 'id'> | null
  ): void {
    if (notification === null) {
      unreadCount.value = 0;
      return;
    }

    const notificationIndex = getNotificationIndexById(notification);
    if (
      notificationIndex === -1 ||
      notifications.value[notificationIndex].read
    ) {
      return;
    }

    const updatedNotification = {
      ...notifications.value[notificationIndex],
      read: true,
    };

    notifications.value.splice(notificationIndex, 1, updatedNotification);
    unreadCount.value -= 1;
  }

  provide(NOTIFICATION_CONTEXT, {
    open,
    close,
    remove,
    hide,
    markAsRead,
    all: readonly(notifications),
    unreadCount: readonly(unreadCount),
  });
}

export function useNotificationContext(): Context {
  const context = inject(NOTIFICATION_CONTEXT);

  if (!context) {
    throw new Error(
      'useNotificationContext must be used with useNotificationProvider'
    );
  }

  return context;
}

<script lang="ts" setup>
import { computed, inject } from 'vue';

const type = inject<'error' | 'info' | 'success' | 'warning'>(
  'type',
  'warning'
);

const textColor = computed(() => {
  switch (type) {
    case 'error':
      return 'text-red-700 hover:text-red-600';
    case 'info':
      return 'text-blue-700 hover:text-blue-600';
    case 'success':
      return 'text-green-700 hover:text-green-600';
    case 'warning':
      return 'text-yellow-700 hover:text-yellow-600';
    default:
      return '';
  }
});
</script>

<template>
  <span class="font-medium underline" :class="textColor">
    <slot />
  </span>
</template>

import { RouteRecordRaw } from 'vue-router';
import { SidebarLayout } from '@/components/layouts';
import { RouteHelper } from '@/router/RouteHelper';
import { OutlineTranslate } from '@/components/icons/heroicons';
import TranslationMemoryPermission from '@/modules/translationmemory/constants/TranslationMemoryPermission';
import { hasPermissionGuard } from '@/router/guards/hasPermissionGuard';
import appOptions from '@/config/app.options';

const routes: RouteRecordRaw[] = [
  {
    path: '/translation-memory',
    name: 'translationMemoryAdministration',
    component: () =>
      import(
        '@/modules/translationmemory/views/TranslationMemoryAdministration'
      ),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasPermissionGuard(TranslationMemoryPermission.read)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineTranslate,
      y: 'bottom',
      roles: appOptions.adminRoleNames,
      permissions: [TranslationMemoryPermission.read],
    },
  },
  {
    path: '/translation-memory-duplicates',
    name: 'translationMemoryDuplicatesAdministration',
    component: () =>
      import(
        '@/modules/translationmemory/views/TranslationMemoryAdministration/TranslationMemoryDuplicatesAdministration.vue'
      ),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasPermissionGuard(TranslationMemoryPermission.read)],
    meta: {
      breadcrumbs: {
        parent: 'translationMemoryAdministration',
      },
      layout: SidebarLayout,
      hidden: true,
      roles: appOptions.adminRoleNames,
      permissions: [TranslationMemoryPermission.read],
    },
  },
  {
    path: '/translation-memory/:id',
    name: 'translationMemoryDetails',
    component: () =>
      import('@/modules/translationmemory/views/TranslationMemoryDetails'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number', null),
    }),
    beforeEnter: [hasPermissionGuard(TranslationMemoryPermission.read)],
    meta: {
      layout: SidebarLayout,
      hidden: true,
      icon: OutlineTranslate,
      breadcrumbs: {
        parent: 'translationMemoryAdministration',
      },
      roles: appOptions.adminRoleNames,
      permissions: [TranslationMemoryPermission.read],
    },
  },
];

export default routes;

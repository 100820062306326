<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  value: number;
  max?: number;
  unit?: string;
  showValue?: boolean;
  colorClass?: string;
}>();

const max = computed<number>(() => props.max || 100);
const unit = computed<string>(() => props.unit || '%');
const showValue = computed<boolean>(() => !!props.showValue);
const colorClass = computed<string>(() => props.colorClass);

const formattedValue = computed<string>(
  () => `${(props.value * 100) / max.value}${unit.value}`
);

const barStyle = computed<{ width: string }>(() => ({
  width: `${(props.value * 100) / max.value}%`,
}));
</script>

<template>
  <div
    class="mt-2 w-full shadow bg-gray-200 dark:bg-gray-600"
    role="progressbar"
    :aria-valuenow="props.value"
    :aria-valuemax="max"
    aria-valuemin="0"
    :style="barStyle"
  >
    <div
      class="py-1 text-center text-white text-xs leading-none"
      :class="colorClass"
    >
      <slot v-if="showValue">{{ formattedValue }}</slot>
    </div>
  </div>
</template>

<style scoped></style>

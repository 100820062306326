import { useRole } from '@/composables/useRole';

export function useMembership(user: User): {
  hasAccess: typeof hasAccess;
  isAdmin: typeof isAdmin;
  isTrusted: typeof isTrusted;
  isAdminWithinAnyMembership: typeof isAdminWithinAnyMembership;
} {
  /**
   * determines if provided user has access to any project
   *
   * @returns {boolean}
   */
  function hasAccessToAllProjects(): boolean {
    if (!user.roles) {
      return false;
    }

    const role = useRole(
      user.roles.map((role) => ({ label: role.name, value: role.id }))
    );

    return role.hasAdminRole.value;
  }

  /**
   * retrieves memberships by id from user memberships
   *
   * @param {number} projectId
   * @returns {Membership | undefined}
   */
  function getMembership(projectId: number): Membership | undefined {
    if (!user.memberships) {
      return undefined;
    }

    return user.memberships.find(
      (membership) => membership.project_id === projectId
    );
  }

  /**
   * determines if the user is allowed to access project as an admin
   *
   * @param {number} projectId
   * @returns {boolean}
   */
  function isAdmin(projectId: number): boolean {
    if (hasAccessToAllProjects()) {
      return true;
    }

    const membership = getMembership(projectId);
    if (!membership || !membership.roles) {
      return false;
    }

    const role = useRole(
      membership.roles.map((role) => ({ label: role.name, value: role.id }))
    );

    return role.hasAdminRole.value;
  }

  function isTrusted(projectId: number): boolean {
    if (hasAccessToAllProjects()) {
      return true;
    }

    if (isAdmin(projectId)) {
      return true;
    }

    const membership = getMembership(projectId);
    if (!membership || !membership.roles) {
      return false;
    }

    return membership.is_trusted;
  }

  /**
   * determines if the user is allowed to general project access
   *
   * @param {number} projectId
   * @returns {boolean}
   */
  function hasAccess(projectId: number): boolean {
    if (hasAccessToAllProjects()) {
      return true;
    }

    const membership = getMembership(projectId);
    return !!membership;
  }

  /**
   * determines if user is project within any membership
   *
   * @returns {boolean}
   */
  function isAdminWithinAnyMembership(): boolean {
    if (hasAccessToAllProjects()) {
      return true;
    }

    const roles = user.memberships.map((membership) =>
      membership.roles.map((role) => ({ label: role.name, value: role.id }))
    );

    const role = useRole(roles.flat());

    return role.hasAdminRole.value;
  }

  return {
    isAdmin,
    isTrusted,
    hasAccess,
    isAdminWithinAnyMembership,
  };
}

import { env } from '@/utils';
import { computed, ref } from 'vue';

const appName = env('TITLE');
const title = ref('');

export function usePageTitle() {
  function setTitle(value: string) {
    title.value = value;
    document.title = `${value} - ${appName}`;
  }

  return {
    title: computed(() => title.value),
    setTitle,
  };
}

<script lang="ts" setup>
import BaseNotification from '@/components/core/BaseNotification/BaseNotification.vue';
import { NotificationInterface, useNotificationContext } from '@/composables';
import { computed } from 'vue';

const notifications = useNotificationContext();
const lastNotifications = computed<NotificationInterface[]>(() =>
  [...notifications.all.value].slice(0, 4)
);
</script>

<template>
  <div class="fixed top-20 z-[1000] w-full max-w-sm md:right-12">
    <div class="flex flex-col">
      <transition-group
        mode="out-in"
        tag="div"
        class="space-y-2"
        enter-active-class="absolute ease-in duration-100 transition"
        enter-class="-translate-x-full opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="absolute transition ease-out duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <BaseNotification
          v-for="notification in lastNotifications"
          v-show="!notification.closed"
          :key="`notification-${notification.id}`"
          :title="notification.title"
          :message="notification.message"
          :type="notification.type"
          :rounded="notification.rounded"
          :time="notification.time"
          @close="notifications.close(notification)"
          @click="notifications.markAsRead(notification)"
          @mouseover="notifications.markAsRead(notification)"
        />
      </transition-group>
    </div>
  </div>
</template>

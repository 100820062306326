import { RouteRecordRaw } from 'vue-router';
import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { OutlineUsersIcon } from '@/components/icons/heroicons';
import appOptions from '@/config/app.options';
import { hasRoleGuard } from '@/router/guards/hasRoleGuard';

export default [
  {
    path: '/admin/users',
    name: 'user_list',
    component: () => import('../views/UserListView.vue'),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasRoleGuard(appOptions.adminRoleNames)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineUsersIcon,
      y: 'bottom',
      roles: appOptions.adminRoleNames,
    },
  },
] satisfies RouteRecordRaw[];

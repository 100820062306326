import BasePermission from '@/modules/permission/base/BasePermission';

export default class BaseCrudPermission extends BasePermission {
  protected _name: string;

  constructor(name: string) {
    super();
    this._name = name;
  }

  get name(): string {
    return this._name;
  }

  get read(): string {
    return this.name + '-read';
  }

  get update(): string {
    return this.name + '-update';
  }

  get delete(): string {
    return this.name + '-delete';
  }

  get create(): string {
    return this.name + '-create';
  }
}

import { RouteRecordRaw } from 'vue-router';
import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { OutlineKey, OutlineUserCircle } from '@/components/icons/heroicons';
import { KeyboardIcon } from '@/components/icons/tablericons';
import { env } from '@/utils';

const routes: RouteRecordRaw[] = [
  {
    path: '/user/:id?/settings',
    name: 'userSettings',
    component: () => import('../views/profile/UserProfileView.vue'),
    props: ({ params }) => ({
      id: RouteHelper.convertParam(params.id, 'number', null),
    }),
    meta: {
      layout: SidebarLayout,
      hidden: true,
    },
    children: [
      {
        path: 'account',
        alias: [''],
        name: 'userSettingsAccount',
        component: () =>
          import('../views/profile/children/UserProfileAccount.vue'),
        meta: {
          icon: OutlineUserCircle,
        },
      },
      {
        path: 'shortkey',
        name: 'userSettingsShortkey',
        component: () =>
          import('../views/profile/children/UserProfileShortkey.vue'),
        meta: {
          icon: KeyboardIcon,
          breadcrumbs: {
            parent: 'userSettingsAccount',
          },
        },
      },
    ],
  },
];

if (env('AUTH') === 'passport') {
  routes[0].children.splice(1, 0, {
    path: 'security',
    name: 'userSettingsSecurity',
    component: () =>
      import('../views/profile/children/UserProfilePassword.vue'),
    meta: {
      enabled: env('AUTH') === 'passport',
      icon: OutlineKey,
      breadcrumbs: {
        parent: 'userSettingsAccount',
      },
    },
  });
}

export default routes;

import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { env } from '@/utils';
import { api } from '@/api';

window.Pusher = Pusher;

let echo = null;

export function useWebsockets(): Echo {
  if (!echo) {
    echo = new Echo({
      broadcaster: 'pusher',
      key: env('SOCKET_KEY'),
      wsHost: env('SOCKET_HOST', window.location.hostname),
      wsPort: env('SOCKET_PORT', 6001),
      forceTLS: false,
      disableStats: true,
      cluster: env('SOCKET_CLUSTER'),
      authorizer: (channel) => {
        return {
          authorize: async (socketId, callback) => {
            try {
              const response = await api.post(env('SOCKET_AUTH_ENDPOINT'), {
                socket_id: socketId,
                channel_name: channel.name,
              });
              callback(null, response.data);
            } catch (error) {
              callback(error);
            }
          },
        };
      },
    });
  }

  return echo;
}

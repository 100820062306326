import { setLocale } from 'yup';
import { LocaleObject } from 'yup/lib/locale';

export class Yup {
  /**
   * Loads the given validation language.
   *
   * @param locale
   * @returns async
   */
  static async loadAsync(locale: string): Promise<void> {
    const translation = await Yup.loadLang(locale);
    setLocale(translation.default);
  }

  /**
   * Load the language file for the given locale.
   *
   * @param {string} locale
   * @returns {Promise<void>}
   * @protected
   */
  protected static async loadLang(
    locale: string
  ): Promise<{ default: LocaleObject }> {
    return import(`../lang/yup/${locale}.ts`);
  }
}

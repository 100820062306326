import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { LocalStorage, UserService } from '@/services';
import { Locale, Logger } from '@/utils';
import { useSettingsStore } from '@/store';
import appOptions from '@/config/app.options';

export const useUserStore = defineStore('user', () => {
  const settingsStore = useSettingsStore();

  const user = ref<User>();

  const roleNames = computed(() => {
    if (!user.value) {
      return [];
    }

    if (!user.value.roles) {
      return [];
    }

    return user.value.roles.map((role) => role.name);
  });

  const permissionNames = computed(() => {
    if (!user.value) {
      return [];
    }

    if (!user.value.roles) {
      return [];
    }

    return user.value.roles
      .map((role) => role.permissions.map((permission) => permission.name))
      .flat();
  });

  const isSuperAdmin = computed(() => hasRole('SuperAdmin'));
  const isAdmin = computed(() => hasRole('Admin') || isSuperAdmin.value);
  const isFinancialManager = computed(() => hasRole('FinancialManager'));
  const isTranslatorPlus = computed(
    () => hasRole('Translator+') || isAdmin.value
  );

  const preferredSourceLanguageTagCode = computed((): null | string => {
    if (!user.value) {
      return null;
    }

    if (!user.value.rw_languages?.length) {
      return null;
    }

    const sourceLanguages =
      appOptions.availableProjectSourceLanguages[
        appOptions.Projects.ArticleDescriptions
      ];

    return (
      user.value.rw_languages.find((language) =>
        sourceLanguages.includes(language.code)
      )?.code || null
    );
  });

  const preferredDestinationLanguageTagCode = computed((): null | string => {
    if (!user.value) {
      return null;
    }

    if (
      !user.value.rw_languages?.length ||
      !preferredSourceLanguageTagCode.value
    ) {
      return null;
    }

    return (
      user.value.rw_languages.filter(
        (language) => preferredSourceLanguageTagCode.value !== language.code
      )?.[0]?.code || null
    );
  });

  /**
   * @description Checks if the user is using a OSX device
   * @type {ComputedRef<boolean>}
   */
  const isOnMac = computed(
    () =>
      (navigator?.['userAgentData']?.platform || navigator?.platform)
        ?.toUpperCase()
        ?.indexOf('MAC') >= 0
  );

  async function getCurrent(): Promise<User> {
    if (user.value) {
      return user.value;
    }

    try {
      const response = await UserService.current();
      user.value = response.data;
      settingsStore.setCurrentSettings(response.data.settings);

      if (!settingsStore.settings.locale) {
        return;
      }

      LocalStorage.set(
        'locale',
        settingsStore.settings.locale.value.toString()
      );

      await Locale.updateFrontendLocale(
        settingsStore.settings.locale.value.toString()
      );

      return user.value;
    } catch (error) {
      Logger.error('Obtaining current user failed', error);
      throw error;
    }
  }

  function hasPermission(name: string): boolean {
    return !!user.value.roles?.find(({ permissions }) =>
      permissions.find(
        ({ name: permissionName }) =>
          permissionName.toLowerCase() === name.toLowerCase()
      )
    );
  }

  function hasRole(name: string): boolean {
    return roleNames.value.includes(name);
  }

  async function update(
    userData: WithPasswordConfirmation<User>
  ): Promise<User> {
    const { data } = await UserService.update(userData);
    user.value = data;

    return data;
  }

  return {
    user,
    getCurrent,
    roleNames,
    permissionNames,
    isSuperAdmin,
    isAdmin,
    isFinancialManager,
    isTranslatorPlus,
    hasPermission,
    hasRole,
    preferredSourceLanguageTagCode,
    preferredDestinationLanguageTagCode,
    update,
    isOnMac,
  };
});

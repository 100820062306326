import { v4 } from 'uuid';
import { TaskSegment } from '@/modules/articles/entities/TaskSegment';

export class TaskSegments {
  readonly tag: TaskSegmentMetaTag = null;
  segments: { source: TaskSegment; destination: TaskSegment }[] = null;
  public isLoading = false;

  static createEmpty(
    segmentsCount = 3,
    type: TaskSegmentMetaTagType = '@@'
  ): TaskSegments {
    const tag = {
      name: 'tag',
      position: 0,
      segment_position: 0,
      type,
      uuid: v4(),
    };
    return new this({
      tag,
      segments: new Array(segmentsCount).fill(null).map(() =>
        this.createNewSegmentOutOfSegmentPair(tag, {
          source: { meta: {} },
          destination: { meta: {} },
        } as TaskSegmentSourceDestination)
      ),
    });
  }

  static createNewSegmentOutOfSegmentPair(
    tag: TaskSegmentMetaTag,
    segment: TaskSegmentSourceDestination
  ): TaskSegmentSourceDestination {
    const { source, destination } = segment;

    return {
      source: {
        ...source,
        id: null,
        text: '',
        task_content_id: null,
        meta: {
          ...source.meta,
          tag: {
            ...source.meta.tag,
            name: tag.name,
            type: tag.type,
            uuid: tag.uuid,
          },
        },
      },
      destination: {
        ...destination,
        id: null,
        text: '',
        task_content_id: null,
        meta: {
          ...destination.meta,
          tag: {
            ...destination.meta.tag,
            name: tag.name,
            type: tag.type,
            uuid: tag.uuid,
          },
        },
      },
    };
  }

  constructor({
    tag,
    segments,
    isLoading = false,
  }: {
    tag: TaskSegmentMetaTag;
    segments: TaskSegmentSourceDestination[];
    isLoading?: boolean;
  }) {
    this.tag = tag;
    this.segments = segments
      .sort(
        (a, b) =>
          a.source.meta.tag.segment_position -
          b.source.meta.tag.segment_position
      )
      .map((segment) => ({
        source: new TaskSegment(segment.source),
        destination: new TaskSegment(segment.destination),
      }));

    this.isLoading = isLoading;
  }

  get position(): number {
    return this.tag.position;
  }

  append(): void {
    const lastSegment = TaskSegments.createNewSegmentOutOfSegmentPair(
      this.tag,
      this.segments[this.segments.length - 1]
    );

    this.segments.push({
      source: new TaskSegment(lastSegment.source),
      destination: new TaskSegment(lastSegment.destination),
    });
  }

  prepend(): void {
    const firstSegment = TaskSegments.createNewSegmentOutOfSegmentPair(
      this.tag,
      this.segments[0]
    );

    this.segments.unshift({
      source: new TaskSegment(firstSegment.source),
      destination: new TaskSegment(firstSegment.destination),
    });
  }
}

import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { OutlineBookOpen } from '@/components/icons/heroicons';
import GlossaryTermPermission from '@/modules/glossary/term/constants/GlossaryTermPermission';
import { RouteRecordRaw } from 'vue-router';
import { hasPermissionGuard } from '@/router/guards/hasPermissionGuard';

export default [
  {
    path: '/glossary/:id/terms',
    name: 'glossary_terms',
    component: () => import('../views/GlossaryTermList.vue'),
    props: ({ params, query }) => ({
      id: RouteHelper.convertParam(params.id, 'number'),
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasPermissionGuard(GlossaryTermPermission.read)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineBookOpen,
      hidden: true,
      breadcrumbs: {
        parent: 'glossaries',
      },
    },
  },
] satisfies RouteRecordRaw[];

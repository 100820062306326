import { reactive } from 'vue';

export function useDebounce(): {
  debounce: typeof debounce;
  clearDebounce: typeof clearDebounce;
  reset: typeof reset;
} {
  const lastDebounce = reactive({
    timer: null,
    callback: null,
    delay: 300,
  });

  /**
   *
   * @param {() => void} callback
   * @param {number} delay in milliseconds
   */
  function debounce(callback: () => void, delay = 300): void {
    clearTimeout(lastDebounce.timer);

    lastDebounce.callback = callback;
    lastDebounce.delay = delay;

    lastDebounce.timer = setTimeout(() => {
      lastDebounce.callback();
    }, lastDebounce.delay);
  }

  function clearDebounce(): void {
    clearTimeout(lastDebounce.timer);
  }

  /**
   * restart delay
   */
  function reset(): void {
    if (!lastDebounce.callback) {
      return;
    }

    clearTimeout(lastDebounce.timer);

    lastDebounce.timer = setTimeout(() => {
      lastDebounce.callback();
    }, lastDebounce.delay);
  }

  return {
    debounce,
    clearDebounce,
    reset,
  };
}

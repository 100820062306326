// eslint-disable-next-line
// @ts-ignore
import { ContentJob as ContentJobInterface } from '@/types/api';

export class ContentJob {
  readonly id: number;
  readonly name: string;
  readonly briefing: string;
  readonly requesting_user_id: number;
  readonly type: ContentJobType;
  readonly responsible_user_id: number;
  readonly due_date: string;
  readonly priority: number;
  readonly project_id: number;
  readonly deleted_at?: string;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly responsible_user?: User;

  static createEmpty(): ContentJob {
    return new this({
      id: null,
      name: '',
      briefing: '',
      requesting_user_id: null,
      type: '',
      responsible_user_id: '',
      due_date: '',
      priority: '',
      project_id: '',
      deleted_at: '',
      created_at: '2023-03-02T05:19:05.000000Z',
      updated_at: '2023-03-02T05:19:05.000000Z',
    });
  }

  constructor(contentJob: ContentJobInterface) {
    this.id = contentJob.id;
    this.name = contentJob.name;
    this.briefing = contentJob.briefing;
    this.requesting_user_id = contentJob.requesting_user_id;
    this.type = contentJob.type;
    this.responsible_user_id = contentJob.responsible_user_id;
    this.due_date = contentJob.due_date;
    this.priority = contentJob.priority;
    this.project_id = contentJob.project_id;
    this.deleted_at = contentJob.deleted_at;
    this.created_at = contentJob.created_at;
    this.updated_at = contentJob.updated_at;
    this.responsible_user = contentJob.responsible_user;
  }
}

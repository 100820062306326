import { defineStore } from 'pinia';
import { computed, ComputedRef, ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import appShortkeys from '@/config/app.shortkeys';
import { LocalStorage, UserService } from '@/services';
import { Locale } from '@/utils';
import { useUserStore } from '@/store';

export const useSettingsStore = defineStore('settings', () => {
  const userStore = useUserStore();

  const settings = ref<Dictionary<Setting>>({
    shortkeys: cloneDeep(appShortkeys.shortkeys) as Setting,
    macShortkeys: cloneDeep(appShortkeys.macShortkeys) as Setting,
    isThomannEmployee: {
      type: 'boolean',
      key: 'isThomannEmployee',
      value: false,
    },
    isPreviewPinned: {
      key: 'isPreviewPinned',
      type: 'boolean',
      value: false,
    },
  });

  const isThomannEmployee = computed(
    () => settings.value.isThomannEmployee.value as boolean
  );

  const isPreviewPinned = computed(
    () => settings.value.isPreviewPinned.value as boolean
  );

  const shortkeys: ComputedRef<ShortkeySetting> = computed(
    () =>
      settings.value[
        userStore.isOnMac ? 'macShortkeys' : 'shortkeys'
      ] as ShortkeySetting
  );

  function setCurrentSettings(newSettings?: Setting[]): void {
    if (!newSettings) {
      return null;
    }

    const updatedSettings = newSettings.reduce(
      (result: Dictionary<Setting>, setting: Setting) => {
        result[setting.key] = setting;
        return result;
      },
      {}
    );

    settings.value = { ...settings.value, ...updatedSettings };
  }

  async function updateSettings(settings: Setting[]): Promise<Setting[]> {
    // try {
    const response = await UserService.updateSettings(userStore.user, settings);
    setCurrentSettings(response.data);
    return response.data;
    // } catch (error) {
    // TODO: notification
    // throw error;
    // }
  }

  async function updateLocale(locale: string): Promise<void> {
    await updateSettings([
      {
        key: 'locale',
        type: 'string',
        value: locale,
      },
    ]);

    LocalStorage.set('locale', locale);
    await Locale.updateFrontendLocale(locale);
  }

  async function updateIsPreviewPinned(value: boolean): Promise<void> {
    await updateSettings([
      {
        key: 'isPreviewPinned',
        type: 'boolean',
        value: `${value}`,
      },
    ]);
  }

  return {
    settings,
    setCurrentSettings,
    // actions
    updateLocale,
    updateSettings,
    updateIsPreviewPinned,
    // getters
    isPreviewPinned,
    isThomannEmployee,
    shortkeys,
  };
});

import { computed, onBeforeUnmount, readonly, ref } from 'vue';
import Mousetrap from 'mousetrap';
import { useSettingsStore } from '@/store/';

type MousetrapMapKey = string | string[];

type MousetrapMapValue = (
  e: Mousetrap.ExtendedKeyboardEvent,
  combo: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

// for the global shortkeys
const mousetrap = new Mousetrap();
const globalBound = ref(new Map<MousetrapMapKey, MousetrapMapValue>());
const disabled = ref(false);

export function useShortkey(options: { global?: boolean } = {}): {
  key: typeof key;
  disabled: Readonly<typeof disabled>;
  bind: typeof bind;
  unbind: typeof unbind;
  getCallback: typeof getCallback;
  toggle: typeof toggle;
  trigger: typeof trigger;
  handleKey: {
    register: typeof register;
    unregister: typeof unregister;
    unregisterAll: typeof unregisterAll;
  };
} {
  const settingsStore = useSettingsStore();

  options = {
    global: options.global ?? false,
  };
  const localBound = ref(new Map<MousetrapMapKey, MousetrapMapValue>());
  const handleKeys = ref<Array<typeof defaultHandleKey>>([]);

  const defaultHandleKey: (
    character: string,
    modifiers: string[],
    event: KeyboardEvent
  ) => void = Mousetrap.prototype.handleKey;

  Mousetrap.prototype.handleKey = function (
    character: string,
    modifiers: string[],
    event: KeyboardEvent
  ): void {
    defaultHandleKey.apply(this, [character, modifiers, event]);
    for (const handleKey of handleKeys.value) {
      handleKey(character, modifiers, event);
    }
  };

  const key = computed<Partial<Shortkeys>>(() => {
    const result: Partial<Shortkeys> = {};

    for (const group of settingsStore.shortkeys?.value ?? []) {
      if (!result[group.name]) {
        result[group.name] = {};
      }

      for (const sk of group.value) {
        result[group.name][sk.name] = sk;
      }
    }

    return result;
  });

  function bind(
    keys: MousetrapMapKey,
    callback: MousetrapMapValue
  ): Mousetrap.MousetrapInstance {
    (options.global ? globalBound.value : localBound.value).set(keys, callback);
    return mousetrap.bind(keys, wrapCallback(callback));
  }

  function unbind(keys: MousetrapMapKey): Mousetrap.MousetrapInstance {
    (options.global ? globalBound.value : localBound.value).delete(keys);
    return mousetrap.unbind(keys);
  }

  function getCallback(keys: MousetrapMapKey): MousetrapMapValue {
    return (options.global ? globalBound.value : localBound.value).get(keys);
  }

  function wrapCallback(callback: MousetrapMapValue): MousetrapMapValue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e: Mousetrap.ExtendedKeyboardEvent, combo: string): any => {
      if (disabled.value) {
        return;
      }

      return callback(e, combo);
    };
  }

  function trigger(keys: string): Mousetrap.MousetrapInstance {
    return mousetrap.trigger(keys);
  }

  function toggle(): void {
    disabled.value = !disabled.value;
  }

  function register(handleKey: typeof defaultHandleKey): number {
    handleKeys.value.push(handleKey);
    return handleKeys.value.length - 1;
  }

  function unregister(index: number): typeof defaultHandleKey {
    const result = handleKeys.value[index];
    handleKeys.value.splice(index, 1);
    return result;
  }

  function unregisterAll(): void {
    handleKeys.value.splice(0, handleKeys.value.length);
  }

  onBeforeUnmount(() => {
    for (const keys of localBound.value) {
      unbind(keys[0]);
    }
    localBound.value.clear();
    unregisterAll();
  });

  return {
    key,
    disabled: readonly(disabled),
    bind,
    unbind,
    getCallback,
    toggle,
    trigger,
    handleKey: {
      register,
      unregister,
      unregisterAll,
    },
  };
}

<script lang="ts" setup>
import {
  useBreadCrumbsProvider,
  useNotificationProvider,
  useThemeProvider,
  usePageTitle,
} from '@/composables';
import { useRoute, useRouter } from 'vue-router';
import { watch } from 'vue';
import { loadedLocales } from '@/lang';
import { env, Locale } from '@/utils';
import NotificationHandler from '@/components/handlers/NotificationHandler.vue';
import { useI18n } from 'vue-i18n';
import AccountNotActivatedView from '@/modules/user/views/AccountNotActivatedView.vue';
import BaseAlert from '@/components/core/BaseAlert/BaseAlert.vue';
import BaseAlertTextUnderline from '@/components/core/BaseAlert/BaseAlertTextUnderline.vue';
import { useUserStore } from '@/store';
import { RouteHelper } from '@/router/RouteHelper';

const route = useRoute();
const router = useRouter();
const { locale, t } = useI18n();
const { setTitle } = usePageTitle();
const userStore = useUserStore();

useThemeProvider();
useNotificationProvider();
useBreadCrumbsProvider();

if (loadedLocales.length === 0) {
  Locale.updateFrontendLocale(locale.value);
}

watch(
  () => route.name,
  () => {
    if (!route.name) {
      return;
    }
    setTitle(t(`app.navigation.${route.name.toString()}`));
  },
  { immediate: true }
);

router.beforeEach(async (to, from, next) => {
  if (!RouteHelper.isAuthRequired(to)) {
    next();
    return;
  }

  if (!userStore.user) {
    await userStore.getCurrent();
  }

  next();
});
</script>

<template>
  <BaseAlert
    v-if="
      env('SENTRY_ENVIRONMENT', 'production') === 'staging' &&
      !userStore.isSuperAdmin
    "
    type="error"
  >
    {{ $t('environment.staging.message') }}

    <a href="https://langosaurus.thomann.de">
      <BaseAlertTextUnderline
        >{{ t('environment.staging.switch') }}
      </BaseAlertTextUnderline>
    </a>
  </BaseAlert>
  <component
    :is="route.meta.layout || 'div'"
    v-if="!userStore.user || userStore.user?.is_active"
    :user="userStore.user"
  >
    <router-view v-slot="{ Component }">
      <Suspense>
        <transition
          class="overflow-y-auto"
          mode="out-in"
          enter-active-class="transition ease-out duration-200"
          enter-class="translate-x-12 opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-150"
          leave-class="opacity-100"
          leave-to-class="-translate-x-12 opacity-0"
        >
          <component :is="Component" />
        </transition>
      </Suspense>
    </router-view>
  </component>
  <AccountNotActivatedView v-else />
  <ModalsContainer />
  <NotificationHandler />
</template>

import { api } from '@/api';
import { AxiosResponse } from 'axios';

export class TranslationViewService {
  static single(
    taskId: number
  ): Promise<AxiosResponse<TranslationViewSingleResponse>> {
    return api.get(`/api/article-description/translation/task/${taskId}`);
  }
}

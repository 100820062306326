<script lang="ts" setup>
import { computed } from 'vue';

const badgeClass = computed((): string => `text-green--600 bg-green-200`);

const props = defineProps<{
  iconClass?: string;
  badge?: boolean;
  badgeCount?: number;
}>();

const emits = defineEmits<{
  (eventName: 'click');
}>();

function onClick(): void {
  emits('click');
}
</script>

<template>
  <div class="relative" :class="props.iconClass">
    <div
      v-show="props.badge"
      class="absolute -right-1 -top-1 flex items-center justify-center w-4 h-4 text-xs font-medium rounded-lg"
      :class="badgeClass"
      >{{ props.badgeCount }}</div
    >
    <slot name="button">
      <button
        type="button"
        class="flex-shrink-0 ml-auto p-1 rounded-full transition duration-100 ease-in-out hover:bg-gray-100 text-gray-900 dark:hover:bg-gray-700 dark:text-white"
        @click="onClick"
      >
        <slot></slot>
      </button>
    </slot>
  </div>
</template>

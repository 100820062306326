import { RouteRecordRaw } from 'vue-router';
import { EmptyLayout } from '@/components/layouts';

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/AuthView.vue'),
    meta: {
      layout: EmptyLayout,
      disabledWithKeycloak: true,
      hidden: true,
    },
  },
];

export default routes;

<script lang="ts" setup>
import { computed, onMounted, reactive } from 'vue';
import BaseProgressBar from '@/components/core/BaseProgressBar/BaseProgressBar.vue';
import IconWrapper from '@/components/icon/IconWrapper.vue';
import { NotificationType } from '@/components/core/BaseNotification';
import {
  OutlineCheckCircle,
  OutlineInformationCircle,
  OutlineExclamation,
  OutlineXCircle,
  SolidX,
} from '@/components/icons/heroicons';

const props = defineProps<{
  title: string;
  message: string;
  type?: NotificationType;
  rounded?: boolean;
  time?: number;
}>();

const emits = defineEmits<{
  (eventName: 'close'): void;
}>();

const mapTypeToIcon: Record<NotificationType, typeof OutlineXCircle> = {
  error: OutlineXCircle,
  info: OutlineInformationCircle,
  success: OutlineCheckCircle,
  warning: OutlineExclamation,
};

const state = reactive({
  timeLeft: 0,
  interval: 0,
});

const hasTimeLeft = computed<boolean>(() => state.timeLeft > 0);
const shouldAutoClose = computed<boolean>(() => props.time > -1);
const icon = computed<typeof OutlineXCircle>(
  () => mapTypeToIcon[props.type || 'info']
);
const rounded = computed<boolean>(() =>
  typeof props.rounded !== 'undefined' ? props.rounded : true
);

function stopTimer(): void {
  clearInterval(state.interval);
}

function startTimer(): void {
  if (!shouldAutoClose.value) {
    return;
  }

  state.interval = window.setInterval(() => {
    if (!hasTimeLeft.value) {
      close();
      return;
    }

    state.timeLeft -= 10;
  }, 10);
}

function close(): void {
  stopTimer();
  emits('close');
}

onMounted((): void => {
  state.timeLeft = props.time;
  startTimer();
});
</script>

<template>
  <div class="w-full max-w-sm" @mouseover="stopTimer" @mouseleave="startTimer">
    <div
      class="bg-white dark:bg-gray-700 shadow-lg pointer-events-auto"
      :class="{ 'rounded-lg': rounded }"
    >
      <slot>
        <div
          class="overflow-hidden ring-1 ring-black ring-opacity-5"
          :class="{ 'rounded-lg': rounded }"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <component
                  :is="icon"
                  class="h-6 w-6"
                  :class="{
                    ['text-red-400']: type === 'error',
                    ['text-blue-400']: type === 'info',
                    ['text-green-400']: type === 'success',
                    ['text-yellow-400']: type === 'warning',
                  }"
                />
              </div>
              <div class="flex-1 ml-3 pt-0.5 w-0">
                <p
                  class="text-gray-900 dark:text-white text-sm font-medium leading-5"
                >
                  {{ props.title }}
                </p>
                <p
                  class="mt-1 text-gray-600 dark:text-gray-400 text-sm leading-5"
                >
                  {{ props.message }}
                </p>
              </div>
              <div class="flex flex-shrink-0 mb-1 ml-4">
                <IconWrapper>
                  <template v-slot:button>
                    <button
                      type="button"
                      class="flex-shrink-0 ml-auto text-gray-600 rounded-full transition duration-100 ease-in-out"
                      @click="close"
                    >
                      <SolidX class="w-5 h-5" />
                    </button>
                  </template>
                </IconWrapper>
              </div>
            </div>
          </div>

          <BaseProgressBar
            v-if="shouldAutoClose"
            :value="state.timeLeft"
            :max="props.time"
            :show-value="false"
          />
        </div>
      </slot>
    </div>
  </div>
</template>

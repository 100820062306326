import { TaskSegment, TaskSegments } from '.';
import { TaskContentService } from '@/services';
import { AxiosResponse } from 'axios';

export class TaskContent implements TaskContentInterface {
  readonly created_at: string;
  readonly id: number;
  readonly key: string;
  readonly meta: TaskContentMeta;
  readonly text_word_count: number;
  readonly text_character_count: number;
  readonly creditable_performance: number;
  readonly quantifier: Quantifier;
  readonly revision: number;
  readonly task_id: number;
  readonly updated_at: string;
  readonly segments: TaskSegments[] = [];
  isLoading = false;

  static createEmpty(): TaskContent {
    return new this(
      {
        created_at: '01-01-1970T00:00:00',
        id: 0,
        key: '',
        meta: { project_id: 0 },
        quantifier: 'ONE',
        text_word_count: 0,
        text_character_count: 0,
        creditable_performance: 0,
        revision: 0,
        task_id: 0,
        updated_at: '2023-03-02T05:19:05.000000Z',
        segments: [
          TaskSegments.createEmpty(1),
          TaskSegments.createEmpty(1),
          TaskSegments.createEmpty(),
        ],
      },
      true
    );
  }

  constructor(content: TaskContentInterface, isLoading = false) {
    this.created_at = content.created_at;
    this.id = content.id;
    this.key = content.key;
    this.meta = content.meta;
    this.quantifier = content.quantifier;
    this.revision = content.revision;
    this.task_id = content.task_id;
    this.updated_at = content.updated_at;
    this.isLoading = isLoading;
    this.creditable_performance = content.creditable_performance;
    this.text_character_count = content.text_character_count;
    this.text_word_count = content.text_word_count;
    this.segments = content.segments.map(
      (groupWithTag) => new TaskSegments(groupWithTag)
    );
  }

  get allSegments(): { source: TaskSegment; destination: TaskSegment }[] {
    const collection = [];

    this.segments.forEach(({ segments }) => {
      collection.push(...segments);
    });

    return collection;
  }

  get header(): TaskSegmentsHeaderInterface {
    return { key: this.key, quantifier: this.quantifier };
  }

  get segmentsGroupedByTag(): TaskSegments[] {
    return this.segments;
  }

  get allDestinationsHaveText(): boolean {
    const extractTaskSegments = (value: TaskSegments) => value.segments;

    return this.segments
      .flatMap(extractTaskSegments)
      .every(({ destination }) => destination.hasText);
  }

  get allDestinationsDontHaveNbrInText(): boolean {
    const extractTaskSegments = (value: TaskSegments) => value.segments;

    return this.segments
      .flatMap(extractTaskSegments)
      .every(({ destination }) => !destination.hasNbrInText);
  }

  deleteSegment(index: number): void {
    this.segments.splice(index, 1);
  }

  getSegmentPosition(segment: TaskSegment): number {
    let position = 0;

    for (const { segments } of this.segments) {
      for (const { source, destination } of segments) {
        position++;

        if (source.id === segment.id || destination.id === segment.id) {
          return position;
        }
      }
    }

    return position;
  }

  adjustPositions(): void {
    this.segments.forEach((taskSegment, tagPosition) => {
      taskSegment.segments.forEach((segment, idx) => {
        segment.source.meta.tag.position = tagPosition;
        segment.source.meta.tag.segment_position = idx;
        segment.destination.meta.tag.position = tagPosition;
        segment.destination.meta.tag.segment_position = idx;
      });
    });
  }

  async updateAllSegments(
    taskSegments: TaskSegments[]
  ): Promise<AxiosResponse<TaskContentInterface>> {
    const segments = [];
    let position = 1;
    taskSegments.forEach((taskSegment, tagPosition) => {
      taskSegment.segments.forEach((segment, segmentPosition) => {
        segments.push(
          this.formatSegmentForRequest(
            segment.source,
            position,
            tagPosition,
            segmentPosition
          )
        );

        segments.push(
          this.formatSegmentForRequest(
            segment.destination,
            position,
            tagPosition,
            segmentPosition
          )
        );

        position += 1;
      });
    });

    return TaskContentService.bulkUpdate(this.id, {
      ...this,
      segments,
    });
  }

  get sourceText(): string {
    return this.segments
      .flatMap((tagSegmentGroup) => tagSegmentGroup.segments)
      .map((segment) => segment.source.text)
      .map((text) => (text[text.length - 1] !== ' ' ? text + ' ' : text))
      .join('');
  }

  get destinationText(): string {
    return this.segments
      .flatMap((tagSegmentGroup) => tagSegmentGroup.segments)
      .map((segment) => segment.destination.text)
      .map((text) => (text[text.length - 1] !== ' ' ? text + ' ' : text))
      .join('');
  }

  private formatSegmentForRequest(
    segment: TaskSegmentInterface,
    position: number,
    tagPosition: number,
    segmentPosition: number
  ): Record<string, unknown> {
    return {
      id: segment.id,
      language_tag_code: segment.language_tag_code,
      text: segment.text,
      status: segment.status,
      position: position,
      meta: {
        ...segment.meta,
        tag: {
          ...segment.meta.tag,
          position: tagPosition,
          segment_position: segmentPosition,
        },
      },
      reviewed_at: segment.reviewed_at,
    };
  }
}

import { SidebarLayout } from '@/components/layouts';
import { OutlineStatistics } from '@/components/icons/heroicons';
import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/statistics',
    name: 'statisticsUser',
    component: () => import('@/modules/statistics/views/UserStatistics.vue'),
    meta: {
      layout: SidebarLayout,
      icon: OutlineStatistics,
      y: 'bottom',
    },
  },
] satisfies RouteRecordRaw[];

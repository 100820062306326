<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { env } from '@/utils';

const { t } = useI18n();
const dots = ref('');

function changeDots(): void {
  if (dots.value.length >= 3) {
    dots.value = '.';
    return;
  }

  dots.value += '.';
}

(() => {
  setInterval(changeDots, 1000);
})();
</script>

<template>
  <div class="flex justify-center items-center w-full h-screen bg-white">
    <div>
      <header class="flex text-3xl font-medium mb-2 text-slate-800"
        ><h1>{{ t('user.inactiveAccount.heading') }}</h1
        >{{ dots }}
      </header>
      <div class="space-y-2">
        <p>{{ t('user.inactiveAccount.inactiveMessage') }}</p>
        <p> {{ t('user.inactiveAccount.notificationMessage') }}</p>
        <small class="opacity-50">{{ env('TITLE') }}</small>
      </div>
    </div>
  </div>
</template>

export type Color = 'green' | 'yellow' | 'red' | 'gray' | 'blue' | 'pink';

export class Suggestion implements TranslationMemory {
  readonly author: User;
  readonly author_id: number;
  readonly contents: Pagination<Content>;
  readonly created_at: string;
  readonly destination_language_tag_code: string;
  readonly destination_text: string;
  readonly histories: TranslationMemoryHistory[];
  readonly id: number;
  readonly meta: TranslationMemoryMeta;
  readonly similarity: number;
  readonly source_language_tag_code: string;
  readonly source_text: string;
  readonly status: string;
  readonly updated_at: string;
  readonly tags: TagInterface[];

  static createEmpty(): Suggestion {
    return new this({
      author: null,
      author_id: null,
      contents: {} as Pagination<Content>,
      created_at: '',
      destination_language_tag_code: '',
      destination_text: '',
      histories: [],
      id: Math.random(),
      meta: {},
      similarity: 0,
      source_language_tag_code: '',
      source_text: '',
      status: '',
      updated_at: '2023-03-02T05:19:05.000000Z',
      tags: [],
    });
  }

  constructor(data: TranslationMemory) {
    this.author = data.author;
    this.author_id = data.author_id;
    this.contents = data.contents;
    this.created_at = data.created_at;
    this.destination_language_tag_code = data.destination_language_tag_code;
    this.destination_text = data.destination_text;
    this.histories = data.histories;
    this.id = data.id;
    this.meta = data.meta;
    this.similarity = data.similarity;
    this.source_language_tag_code = data.source_language_tag_code;
    this.source_text = data.source_text;
    this.status = data.status;
    this.updated_at = data.updated_at;
    this.tags = data.tags ?? [];
  }

  get textColor(): string {
    return {
      green: 'text-green-500',
      yellow: 'text-yellow-500',
      red: 'text-red-500',
      gray: 'text-gray-500',
      blue: 'text-blue-500',
      pink: 'text-pink-500',
    }[this.similarityColor];
  }

  get backgroundColor(): string {
    return {
      green: 'bg-green-500',
      yellow: 'bg-yellow-500',
      red: 'bg-red-500',
      gray: 'bg-gray-500',
      blue: 'bg-blue-500',
      pink: 'bg-pink-500',
    }[this.similarityColor];
  }

  get borderColor(): string {
    return {
      green: 'border-l-green-500 dark:border-l-green-600',
      yellow: 'border-l-yellow-500 dark:border-l-yellow-600',
      red: 'border-l-red-500 dark:border-l-red-600',
      gray: 'border-l-gray-500 dark:border-l-gray-600',
      blue: 'border-l-blue-500 dark:border-l-blue-600',
      pink: 'border-l-pink-500 dark:border-l-pink-600',
    }[this.similarityColor];
  }

  get percent(): number {
    return Math.trunc(this.similarity) ?? 0;
  }

  private get similarityColor(): Color {
    if (this.similarity >= 100) {
      return 'green';
    }

    if (this.similarity >= 70 && this.similarity < 100) {
      return 'yellow';
    }

    return 'red';
  }
}

import { useUserStore } from '@/store';
import { useMembership } from '@/composables';

export function hasProjectMembershipGuard(
  projectId: number,
  adminRequired = false
) {
  return (to, from, next) => {
    const userStore = useUserStore();
    const userMembership = useMembership(userStore.user);

    if (adminRequired && userMembership.isAdmin(projectId)) {
      return next();
    }

    if (userMembership.hasAccess(projectId)) {
      return next();
    }

    return next('/403');
  };
}

import { RouteRecordRaw } from 'vue-router';
import { DefaultLayout, SidebarLayout } from '@/components/layouts';
import {
  OutlineInformationCircle,
  OutlineUsersIcon,
} from '@/components/icons/heroicons';
import { env } from '@/utils';
import { RouteHelper } from '@/router/RouteHelper';
import {
  ArrowsRandom,
  FloatLeft,
  ListCheck,
  PlayerSkipForward,
} from '@/components/icons/tablericons';
import appOptions from '@/config/app.options';
import TranslationViewV2 from '@/modules/articles/routes';
import { hasProjectMembershipGuard } from '@/router/guards/hasProjectMembershipGuard';
import { hasRoleGuard } from '@/router/guards/hasRoleGuard';
import TranslationMemoryPermission from '@/modules/translationmemory/constants/TranslationMemoryPermission';

let routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'tasks',
    component: () => import('@/views/tasks/TaskListView.vue'),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
      tab: RouteHelper.convertParam(query.tab, 'string', ''),
    }),
    meta: {
      layout: SidebarLayout,
      icon: ListCheck,
    },
  },
  {
    path: '/apidoc',
    strict: true,
    name: 'apidoc',
    redirect: undefined,
    beforeEnter: () => {
      window.location.href = '/apidoc/';
    },
    meta: {
      hidden: true,
    },
  },
  {
    path: '/article',
    name: 'articleText.article',
    redirect: () => {
      return { name: 'articleText.tasks' };
    },
    component: () => import('@/views/article/ArticleView.vue'),
    beforeEnter: [
      hasProjectMembershipGuard(appOptions.Projects.ArticleDescriptions),
    ],
    meta: {
      layout: SidebarLayout,
      icon: FloatLeft,
    },
    children: [
      {
        path: '/article/pool',
        name: 'articleText.pool',
        redirect: (to) => {
          return {
            name: 'articleText.tasks',
            query: { ...to.query, tab: 'pool' },
          };
        },
        meta: {
          icon: ArrowsRandom,
          query: {
            key: 'tab',
            value: 'pool',
          },
        },
      },
      {
        path: '/article/tasks',
        name: 'articleText.tasks',
        component: () => import('@/views/tasks/TaskListView.vue'),
        props: ({ query }) => ({
          page: RouteHelper.convertParam(query.page, 'number', 1),
          tab: RouteHelper.convertParam(query.tab, 'string', ''),
          projectId: appOptions.Projects.ArticleDescriptions,
        }),
        meta: {
          layout: SidebarLayout,
          icon: ListCheck,
          query: {
            key: 'tab',
            value: null,
          },
        },
      },
      {
        path: '/article/skipped',
        name: 'articleText.skipped',
        redirect: () => {
          return { name: 'articleText.tasks', query: { tab: 'skipped' } };
        },
        beforeEnter: [
          hasProjectMembershipGuard(
            appOptions.Projects.ArticleDescriptions,
            true
          ),
        ],
        meta: {
          icon: PlayerSkipForward,
          query: {
            key: 'tab',
            value: 'skipped',
          },
          roles: appOptions.adminRoleNames,
          permissions: [TranslationMemoryPermission.read],
        },
      },
      {
        path: '/article/users',
        name: 'articleText.users',
        component: () =>
          import('@/views/article/text/user/ArticleTextUserListView.vue'),
        props: ({ query }) => ({
          page: RouteHelper.convertParam(query.page, 'number', 1),
          projectId: appOptions.Projects.ArticleDescriptions,
        }),
        beforeEnter: [
          hasProjectMembershipGuard(
            appOptions.Projects.ArticleDescriptions,
            true
          ),
        ],
        meta: {
          layout: SidebarLayout,
          icon: OutlineUsersIcon,
          roles: appOptions.adminRoleNames,
          permissions: [TranslationMemoryPermission.read],
        },
      },
      ...TranslationViewV2,
    ],
  },
];

if (env('DEV', false)) {
  routes = (
    [
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView.vue'),
        beforeEnter: [hasRoleGuard(appOptions.adminRoleNames)],
        meta: {
          layout: DefaultLayout,
          icon: OutlineInformationCircle,
        },
      },
    ] as RouteRecordRaw[]
  ).concat(routes);
}

export default routes;

import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import routes from '@/router/routes';
import { env } from '@/utils';
import { RouteHelper } from '@/router/RouteHelper';
import NProgress from 'nprogress';

const router = createRouter({
  history: createWebHistory(env('BASE_URL', '')),
  routes: RouteHelper.filterDisabledKeycloakRoutes(routes),
});

router.beforeEach(
  async (
    routeTo: RouteLocationNormalized,
    routeFrom: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    // If this isn't an initial page load...
    if (routeFrom.name) {
      // Start the route progress bar.
      NProgress.start();
    }

    if (
      window.location.href.includes('auth?code') ||
      (routeTo.meta && routeTo.meta.error)
    ) {
      return next();
    }

    return next();
  }
);

router.afterEach((): void => {
  NProgress.done();
});

export default router;

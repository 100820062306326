import { RouteRecordRaw, useRouter } from 'vue-router';
import { readonly, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface BreadCrumb {
  name: string; // route name
  label: string; // route label or name
  path: string; // route resolved path i.e. glossary/1
  current: boolean; // if it's the current active route
}

interface BreadCrumbBind {
  name: string; // route name
  bind: string; // replacement for route name i.e. jan kowalski
}

interface BreadCrumbCustom extends BreadCrumb {
  custom: boolean;
}

const stack = ref<BreadCrumb[]>([]);
const before = ref<Dictionary<BreadCrumbCustom>>({});
const binds = ref<BreadCrumbBind[]>([]);

export function useBreadCrumbsProvider(): {
  bindName: typeof bindName;
} {
  const router = useRouter();
  const { t } = useI18n();

  function add(route, current = false): void {
    stack.value.push({
      name: route.name,
      label: t(`app.navigation.${route.name}`),
      path: route?.fullPath || route.path,
      current,
    });

    if (!route.meta?.breadcrumbs?.parent) {
      return;
    }

    add(resolveRouteByName(route.meta.breadcrumbs.parent));
  }

  function buildStack(route): void {
    const previousStack = [...stack.value];
    stack.value = [];
    add(route, true);
    stack.value.reverse();

    const realStack = [];

    stack.value.forEach((route) => {
      const previousStackRoute = previousStack.find(
        (previousRoute) => previousRoute.name === route.name
      );

      if (previousStackRoute) {
        route.path = previousStackRoute.path;
      }

      const currentPath = route.path.startsWith('/')
        ? route.path
        : `/${route.path}`;

      const hasBeforePath = Object.keys(before.value).find((keyRoute) =>
        currentPath.startsWith(keyRoute)
      );

      if (
        hasBeforePath &&
        !realStack.find(
          (breadcrumb) =>
            breadcrumb.custom &&
            before.value[hasBeforePath]?.path === breadcrumb.path
        )
      ) {
        if (before.value[currentPath]) {
          realStack.pop();
        }

        realStack.push(before.value[hasBeforePath]);
      }

      realStack.push(route);
    });

    stack.value = realStack;

    replaceBoundedContexts();
  }

  function resolveRouteByName(name: string): RouteRecordRaw | null {
    return resolveRouteByNameRoutes(name, router.options.routes);
  }

  router.afterEach(async (to, from) => {
    if (to.meta.disableBreadcrumbs) {
      stack.value = [];
      return;
    }

    buildStack(to);

    const stackRouteIndex = stack.value.findIndex(
      (route) => route.name === from.name
    );

    if (stackRouteIndex === -1) {
      return;
    }

    stack.value[stackRouteIndex].path = from.fullPath;
  });

  return {
    bindName,
  };
}

export function useBreadCrumbs(): {
  stack: Readonly<Ref<readonly BreadCrumb[]>>;
  bindName: typeof bindName;
  setBefore: typeof setBefore;
} {
  const { t } = useI18n();

  function setBefore(route, beforePath: string): void {
    if (!beforePath.startsWith('/')) {
      beforePath = `/${beforePath}`;
    }

    before.value[beforePath] = {
      name: route.name,
      label: t(`app.navigation.${route.name}`),
      path: route?.fullPath || route.path,
      current: false,
      custom: true,
    };
  }

  return {
    stack: readonly(stack),
    bindName,
    setBefore,
  };
}

/**
 * replace route label with provided bind
 * e.g. route userAccountSettings can be replaced with Rainer Zufall
 *
 * @param {string} name
 * @param {string} bind
 */
function bindName(name: string, bind: string): void {
  binds.value.push({ name, bind });
  findAndReplaceName(name, bind);
}

function findAndReplaceName(name: string, bind: string): void {
  const stackIndex = stack.value.findIndex((stack) => stack.name === name);

  if (stackIndex === -1) {
    return;
  }

  stack.value[stackIndex].label = bind;
}

function replaceBoundedContexts(): void {
  binds.value.forEach(({ name, bind }) => {
    findAndReplaceName(name, bind);
  });
}

function resolveRouteByNameRoutes(
  name: string,
  routes: readonly RouteRecordRaw[]
): RouteRecordRaw | null {
  for (const route of routes) {
    if (route.name === name) {
      return route;
    }

    if (!hasRouteChildren(route)) {
      continue;
    }

    const resolvedRoute = resolveRouteByNameRoutes(name, route.children);

    if (!resolvedRoute) {
      continue;
    }

    return resolvedRoute;
  }

  return null;
}

function hasRouteChildren(route): boolean {
  return !!(route.children && route.children.length > 0);
}

import options from '@/config/app.options';
import i18n, { loadedLocales } from '@/lang';
import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { env } from '@/utils/Env';
import { App } from 'vue';
import LocaleInitializer from '@/utils/locale/LocaleInitializer';
import { Yup } from '@/utils/Yup';
import { LocalStorage } from '@/services/storage';
import { locale as changeDayjsLocale } from 'dayjs';

export class Locale extends LocaleInitializer {
  /**
   * If the locale of the destination route is different than the locale
   * of the current route load the new language file and go ahead.
   *
   * NOTE:
   * When localized links are enabled add this middleware to the routers
   * beforeEach hook to dynamically load the language file for the current
   * route.
   *
   * @param {RouteLocationNormalized} to
   * @param {RouteLocationNormalized} from
   * @param {(to?: (RouteLocationRaw | false | ((vm: Vue) => unknown) | void)) => void} next
   * @returns
   */
  static async localizedRoutesRouteMiddleware(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (
      to?: RouteLocationRaw | false | ((vm: App) => unknown) | void
    ) => void
  ): Promise<void> {
    if (!options.i18nConfig.useLocalizedLinks) {
      return next();
    }

    if (to.params.locale === from.params.locale) {
      return next();
    }

    const { locale } = to.params;
    await Locale.loadAsync(Array.isArray(locale) ? locale[0] : locale);

    next();
  }

  /**
   * Replace the current routes locales with the given one and return
   * the new route.
   */
  static buildLocalizedRoute(path: string, locale: string): string {
    const parts = path.split('/');
    if (parts.length >= 2) {
      parts[1] = locale;
    }

    return parts.join('/');
  }

  /**
   * Get the supported locales of the application specified
   * in app.options.
   *
   * @returns supported locales
   */
  static getSupportedLocales(): Dictionary<Locale> {
    const locales: Dictionary<Locale> = {};
    const supportedLocales = options.i18nConfig.supportedLocales;

    for (const code of Object.keys(supportedLocales)) {
      locales[code] = {
        code,
        name: supportedLocales[code],
      };
    }

    return locales;
  }

  /**
   * Determine if the given locale is already loaded.
   *
   * @returns true if the locale is already loaded otherwise false
   */

  static isLocaleLoaded(locale: string): boolean {
    return loadedLocales.includes(locale);
  }

  /**
   * Load the initial language file based on the user's preferences.
   *
   * @returns {Promise<string>}
   */
  static async loadInitial(): Promise<string> {
    return Locale.loadAsync(Locale.getStartingLocale());
  }

  /**
   * Load the given locale.
   *
   * @param {string} locale
   * @returns {Promise<void>}
   */
  static async updateFrontendLocale(locale: string): Promise<void> {
    await Promise.all([Locale.loadAsync(locale), Yup.loadAsync(locale)]);
  }

  /**
   * Loads the given language.
   *
   * @param lang
   * @returns async
   */
  static async loadAsync(lang: string): Promise<string> {
    // If the same language
    if (loadedLocales.length > 0 && i18n.global.locale.value === lang) {
      return Promise.resolve(Locale.setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLocales.includes(lang)) {
      return Promise.resolve(Locale.setI18nLanguage(lang));
    }

    // If the language is not supported, use the fallback language
    if (!Locale.isSupported(lang)) {
      return Promise.resolve(
        Locale.setI18nLanguage(options.i18nConfig.fallbackLocale)
      );
    }

    // If the language hasn't been loaded yet
    return Locale.loadLang(lang).catch((e) => {
      const fallback = options.i18nConfig.fallbackLocale;

      if (lang === fallback) {
        throw e;
      }

      return Locale.loadAsync(fallback);
    });
  }

  /**
   * Get the current locale.
   *
   * @returns current locale
   */
  static getCurrentLocale(): string {
    return i18n.global.locale.value;
  }

  /**
   * Sets i18n language.
   *
   * @param locale
   * @returns i18n language
   */
  protected static setI18nLanguage(locale: string): string {
    // in this case i18n takes supported languages from the i18n config provided, we're detecting the language so we can not provide correct typing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    i18n.global.locale.value = locale;
    changeDayjsLocale(locale);

    document.dir = locale === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = locale;
    document.title = env('TITLE');
    LocalStorage.set('locale', locale);

    return locale;
  }

  /**
   * Load the language file for the given language.
   *
   * @param lang
   * @returns
   */
  protected static async loadLang(lang: string): Promise<string> {
    // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations

    const { 0: messages, 1: dayjsMessages } = await Promise.all([
      import(`../../lang/${lang}.ts`),
      import(`../../../node_modules/dayjs/esm/locale/${lang}.js`),
    ]);

    i18n.global.setLocaleMessage(lang, messages.default);
    changeDayjsLocale(lang, dayjsMessages.default);

    loadedLocales.push(lang);

    return Locale.setI18nLanguage(lang);
  }
}

import { computed, Ref, isRef } from 'vue';
import appOptions from '@/config/app.options';

export function useRole(
  roles: Ref<SelectableItem[]> | SelectableItem[] = null
): {
  adminRoleIds: typeof adminRoleIds;
  adminRoleNames: string[];
  hasAdminRole: typeof hasAdminRole;
} {
  const availableRoles = computed<SelectableItem[]>(() => {
    if (!roles) {
      return [];
    }

    if (isRef(roles)) {
      return roles.value;
    }

    return roles as SelectableItem[];
  });

  const hasAdminRole = computed(() => !!adminRoleIds.value.length);

  /** provides mapping for retrieving admin roles from provided roles */
  const adminRoleIds = computed<number[]>(() => {
    return availableRoles.value
      .filter((selectable) =>
        appOptions.adminRoleNames.includes(selectable.label)
      )
      .map((r) => r.value as number);
  });

  return {
    adminRoleIds,
    adminRoleNames: appOptions.adminRoleNames,
    hasAdminRole,
  };
}

import {
  computed,
  inject,
  InjectionKey,
  onMounted,
  provide,
  readonly,
  ref,
} from 'vue';
import { LocalStorage } from '@/services';

interface Context {
  toggle: () => void;
  theme: typeof theme;
}

type ThemeType = 'dark' | 'light';

const initialTheme = computed<ThemeType>(() => {
  if (
    !LocalStorage.has('theme') &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  }

  if (LocalStorage.has('theme')) {
    return LocalStorage.get('theme') as ThemeType;
  }

  return 'light';
});

const theme = ref<ThemeType>(null);

// https://logaretm.com/blog/type-safe-provide-inject/
const THEME_CONTEXT: InjectionKey<Context> = Symbol('theme');

export function useThemeProvider(): void {
  onMounted((): void => {
    if (initialTheme.value !== 'dark') {
      return;
    }

    toggle();
  });

  function toggle(): void {
    document.body.classList.toggle('dark');
    theme.value = theme.value === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', theme.value);
  }

  provide(THEME_CONTEXT, {
    toggle,
    theme: readonly(theme),
  });
}

export function useThemeContext(): Context {
  const context = inject(THEME_CONTEXT);

  if (!context) {
    throw new Error('useThemeContext must be used with useThemeProvider');
  }

  return context;
}

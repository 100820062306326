import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { OutlineBookOpen } from '@/components/icons/heroicons';
import GlossaryPermission from '@/modules/glossary/constants/GlossaryPermission';
import { hasPermissionGuard } from '@/router/guards/hasPermissionGuard';
import { RouteRecordRaw } from 'vue-router';
import appOptions from '@/config/app.options';

export default [
  {
    path: '/glossaries',
    name: 'glossaries',
    component: () => import('../views/GlossaryList.vue'),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasPermissionGuard(GlossaryPermission.read)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineBookOpen,
      y: 'bottom',
      roles: appOptions.adminRoleNames,
      permissions: [GlossaryPermission.read],
    },
  },
] satisfies RouteRecordRaw[];

import { SidebarLayout } from '@/components/layouts';
import appOptions from '@/config/app.options';
import { OutlineStatistics } from '@/components/icons/heroicons';
import { hasRoleGuard } from '@/router/guards/hasRoleGuard';
import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/admin/statistics',
    name: 'statistics',
    component: () => import('@/modules/statistics/views/EmbededStatistics.vue'),
    beforeEnter: [hasRoleGuard(appOptions.adminRoleNames)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineStatistics,
      y: 'bottom',
      roles: appOptions.adminRoleNames,
    },
  },
] satisfies RouteRecordRaw[];

import { App } from 'vue';
import { env, setEnv } from '@/utils';
import options from '@/config/app.options';

const app: Application = {
  env,
  setEnv,
  options,
};

export default {
  install: (vueApp: App): void => {
    vueApp.config.globalProperties.app = app;
    vueApp.config.globalProperties.$app = app;

    vueApp.provide('app', app);
  },
};

import { useUserStore } from '@/store';

/**
 * checks if user has one of the given role names
 *
 * @param {string[]} role
 * @returns {(from, to, next) => any}
 */
export function hasRoleGuard(roles: string[] | string) {
  roles = Array.isArray(roles) ? roles : [roles];

  return (to, from, next) => {
    const userStore = useUserStore();

    if (userStore.roleNames.find((name) => roles.includes(name))) {
      return next();
    }

    next('/403');
  };
}

import { ref, readonly } from 'vue';
import { env } from '@/utils';

const isActive = ref<boolean>(env('AUTH') === 'keycloak');

export function useKeycloak(): { isActive: typeof isActive } {
  return {
    isActive: readonly(isActive),
  };
}
